import React, { useState, useEffect } from 'react';
import styled, { css } from "styled-components";
import { connect } from 'react-redux';
import { parse as parsePath, } from 'query-string';
import { useParams, useHistory, useLocation } from 'react-router-dom';

import { AppState } from '../../store/RootReducer';
import { ColorTheme, ScreenSize } from '../../styling/constants';
import { triggerFetchOrderDetails, triggerFetchProducts } from '../../store/ui/OrderStatusView/actions';
import { OrderStatusViewActionType } from '../../store/ui/OrderStatusView/types';
import { OrderStatusDetails } from '../../repositories/OrderRepository';
import { StoreDetails } from '../../entities/StoreDetails';
import { StoreProduct } from '../../entities/StoreProduct';
import usePrevious from '../../hooks/usePrevious';
import { GetProductsRequest } from '../../repositories/ProductRepository';
import { CartActionType } from '../../store/cart/types';
import { clearCart } from '../../store/cart/actions';
import { getPageTitlteSuffix } from '../util';

import FetchingOrderDetailsErrorView from './FetchingOrderDetailsErrorView';
import RecommendedProductsContent from './RecommendedProductsContent';
import FailedOrderView from './FailedOrderView';
import LoadingPageView from '../LoadingPageView';
import StatusTimeline from './StatusTimeline';
import DeliveryPaymentContent from './DeliveryPaymentContent';
import PriceBreakdownContent from './PriceBreakdownContent';
import OrderItemsContent from './OrderItemsContent';
import CancelOrderContent from './CancelOrderContent';
import { Container, CARDS_SPACING, RECOMMENDED_ITEMS_COUNT } from './style';
import { ReactComponent as SuccesIcon } from '../../assets/icons/success-icon.svg';
import { ReactComponent as ErrorIcon } from '../../assets/icons/error.svg';
import { FormattedMessage } from 'react-intl';

interface StatusIconContainerProps {
  error?: boolean;
}

const StatusIconContainer = styled.div<StatusIconContainerProps>`
  width: 70px;
  height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: rgba(46, 181, 126, 0.1);
  margin-bottom: 40px;

  svg {
    height: 24px;
    width: 24px;
    fill: green;
  }

  ${({ error }) => error && css`
    background-color: #fceeee;

    svg {
      fill: #e55f5f;
    }
  `}


`;

const TitleMessage = styled.div`
  margin-bottom: 30px;

  h2 {
    font-size: 2.4rem;
    color: ${ColorTheme.primaryColorDark};
    text-align: center;
  }
`;

const OrderMessage = styled.div`
  margin-bottom: 30px;
  
  p {
    font-size: 1.8rem;
    text-align: center;
  }
`;

const NotificationMessage = styled.div`
  max-width: 720px;
  margin-bottom: 50px;

  p {
    text-align: center;
  }
`;

const StatusTimelineContainer = styled.div`
  margin-bottom: 65px;
`;

const DetailsTopContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: ${CARDS_SPACING}px;

  @media (min-width: ${ScreenSize.XS}px) {
    flex-direction: row;
  }
`;

const DeliveryPaymentContainer = styled.div`
  flex: 2;
  margin-bottom: ${CARDS_SPACING}px;

  @media (min-width: ${ScreenSize.XS}px) {
    margin-bottom: 0px;
    margin-right: ${CARDS_SPACING}px;
  }
`;

const PriceBreakdownContainer = styled.div`
  flex: 1;
`;

const DetailsBottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 50px;

  @media (min-width: ${ScreenSize.XS}px) {
    flex-direction: row;
  }
`;

const OrderItemsContainer = styled.div`
  flex: 2;
  /* margin-bottom: ${CARDS_SPACING}px; */

  @media (min-width: ${ScreenSize.XS}px) {
    flex-direction: row;
    margin-bottom: 0px;
    /* margin-right: ${CARDS_SPACING}px; */
  }
`;

const CancelOrderContainer = styled.div`
  flex: 1;
  display: none;
`;


const RecommendedProductsContainer = styled.div`
  width: 100%;
`;


interface RouteParams {
  storeUrlEndpoint: string;
  orderId: string;
}

interface StoreStateProps {
  cartInitialised: boolean;
  storeDetails: StoreDetails;

  fetchingOrderDetails: boolean;
  errorFetchingOrderDetails: boolean;
  orderDetails: OrderStatusDetails | null;

  fetchingProducts: boolean;
  fetchingProductsError: boolean;
  products: StoreProduct[];
}

interface StoreDispatchProps {
  clearCart: () => void;
  fetchOrderDetails: (orderId: string) => void;
  fetchProducts: (storeUrlEndpoint: string, request: GetProductsRequest) => void;
}

interface OwnProps {

}

type Props = StoreStateProps & StoreDispatchProps & OwnProps;


function OrderStatusView(props: Props) {
  const {
    cartInitialised,
    storeDetails,

    fetchingOrderDetails,
    errorFetchingOrderDetails,
    orderDetails,

    fetchingProducts,
    fetchingProductsError,
    products,

    clearCart,
    fetchOrderDetails,
    fetchProducts
  } = props;

  const { urlEndpoint: storeUrlEndpoint } = storeDetails;

  const prevFetchingOrderDetails = usePrevious(fetchingOrderDetails);

  const { orderId } = useParams<RouteParams>();

  const history = useHistory();

  const location = useLocation();

  const fetchRecommendedProducts = () => {
    fetchProducts(storeUrlEndpoint, {
      pageNumber: 0,
      pageSize: RECOMMENDED_ITEMS_COUNT,
      sortBy: "RECOMMENDED",
    });
  }

  const updateCartStatus = () => {
    const parsedParams = parsePath(location.search);

    const { response } = parsedParams;

    // if payment was successful clear items in cart
    if (response === '00') {
      clearCart();
    }

    history.push(location.pathname);
  };

  useEffect(() => {
    // Scroll to top on render
    window.scrollTo(0, 0);

    // Change page title
    const { name } = storeDetails;
    document.title = `${getPageTitlteSuffix(name)}`;

  }, []);

  useEffect(() => {
    if (!cartInitialised) return;
    updateCartStatus();
  }, []);

  useEffect(() => {
    if (!cartInitialised) return;
    updateCartStatus();
  }, [cartInitialised]);


  useEffect(() => {
    fetchOrderDetails(orderId);
  }, []);



  useEffect(() => {
    if (prevFetchingOrderDetails && !fetchingOrderDetails && !errorFetchingOrderDetails) {
      fetchRecommendedProducts();
    }
  }, [fetchingOrderDetails]);


  const navigateToCart = () => {
    history.push({ pathname: `/${storeUrlEndpoint}/cart` });
  }

  if (fetchingOrderDetails) {
    return <LoadingPageView />;
  }

  if (errorFetchingOrderDetails) {
    return <FetchingOrderDetailsErrorView actionHandler={() => fetchOrderDetails(orderId)} />;
  }

  if (!orderDetails) return null;

  const { paymentStatus } = orderDetails;

  if (paymentStatus !== 'SUCCESSFUL') {
    return <FailedOrderView actionHandler={navigateToCart} />;
  }

  const {
    cardNumber,
    customerName,
    customerPhoneNumber,
    orderShippingInfo,
    customerEmail,
    orderItems,
    currencyCode,
    channel,
    createdOn,
    orderStatus
  } = orderDetails;

  const {
    customerFullAddress,
    fee: deliveryFee,
    dateShipped,
    dateDelivered,
    deliveryMethod
  } = orderShippingInfo;

  const orderCancelled =
    orderStatus == "FAILED_PICKUP"
    || orderStatus == "FAILED_DELIVERY"
    || orderStatus == "CANCELED";

  return (
    <Container>

      {!orderCancelled && (
        <>
          <StatusIconContainer>
            <SuccesIcon />
          </StatusIconContainer>


          <TitleMessage>
            <h2>
              <FormattedMessage id="thankYou" defaultMessage="Thank You!" />
            </h2>
          </TitleMessage>


          <OrderMessage>
            <p><FormattedMessage id="yourOrder" defaultMessage="Your order " /> <b>#{orderId}</b> <FormattedMessage id="hasBeenPlaced" defaultMessage="has been placed!" /></p>
          </OrderMessage>


          <NotificationMessage>
            <p>
            <FormattedMessage id="sentAnEmail" defaultMessage="We sent an email to " /> <b>{customerEmail}</b> <FormattedMessage id="orderNotificationMsg" defaultMessage=" with your order 
          confirmation and receipt. If the email hasn’t arrived 
          within two minutes, please check your spam folder to see 
          if the email was routed there." />
            </p>
          </NotificationMessage>
        </>
      )}


      {orderCancelled && (
        <>
          <StatusIconContainer error>
            <ErrorIcon />
          </StatusIconContainer>

          <OrderMessage>
            <p>
              <FormattedMessage id="orderCancelled" defaultMessage="Your order has been cancelled!" />
            </p>
          </OrderMessage>


          <NotificationMessage>
            <p>
            <FormattedMessage id="yourOrder" defaultMessage="Your order " /> <b>#{orderId}</b> <FormattedMessage id="cancelledByStore" defaultMessage="has been cancelled byt the Store. An email was sent to" /> <b>{customerEmail}</b> <FormattedMessage id="withFurtherInfo" defaultMessage="with further information." />
            </p>
          </NotificationMessage>
        </>
      )}

      <StatusTimelineContainer>
        <StatusTimeline
          deliveryMethod={deliveryMethod}
          orderCancelled={orderCancelled}
          orderStatus={orderStatus}
          shippingOrderStatus={orderShippingInfo.status}
          orderDate={createdOn ? new Date(createdOn) : null}
          packageDate={dateShipped ? new Date(dateShipped) : null}
          shippingDate={dateShipped ? new Date(dateShipped) : null}
          deliveryDate={dateDelivered ? new Date(dateDelivered) : null}
        />
      </StatusTimelineContainer>


      <DetailsTopContainer>

        <DeliveryPaymentContainer>
          <DeliveryPaymentContent
            deliveryMethod={deliveryMethod}
            name={customerName}
            address={customerFullAddress}
            phoneNumber={customerPhoneNumber}
            cardType={'-'}
            cardNumber={cardNumber}
            channel={channel}
          />
        </DeliveryPaymentContainer>

        <PriceBreakdownContainer>
          <PriceBreakdownContent
            orderItems={orderItems}
            currencyCode={currencyCode}
            deliveryFee={deliveryFee}
          />
        </PriceBreakdownContainer>

      </DetailsTopContainer>


      <DetailsBottomContainer>

        <OrderItemsContainer>
          <OrderItemsContent
            orderItems={orderItems}
            currencyCode={currencyCode}
          />
        </OrderItemsContainer>

        <CancelOrderContainer>
          <CancelOrderContent />
        </CancelOrderContainer>

      </DetailsBottomContainer>

      <RecommendedProductsContainer>
        <RecommendedProductsContent
          storeUrlEndpoint={storeUrlEndpoint}
          loading={fetchingProducts}
          errorLoading={fetchingProductsError}
          products={products}
        />
      </RecommendedProductsContainer>

    </Container>
  );
}

const mapStateToProps = (state: AppState): StoreStateProps => ({
  cartInitialised: state.cart.cartInitialised,

  storeDetails: state.store.storeDetails as StoreDetails,

  fetchingOrderDetails: state.ui.orderStatusView.fetchingOrderDetails,
  errorFetchingOrderDetails: state.ui.orderStatusView.errorFetchingOrderDetails,
  orderDetails: state.ui.orderStatusView.orderDetails,

  fetchingProducts: state.ui.orderStatusView.fetchingProducts,
  fetchingProductsError: state.ui.orderStatusView.fetchingProductsError,
  products: state.ui.orderStatusView.products,
});

const mapDispatchToProps = (dispath: (action: CartActionType | OrderStatusViewActionType) => void): StoreDispatchProps => ({
  clearCart() {
    dispath(clearCart());
  },
  fetchOrderDetails(orderId: string) {
    dispath(triggerFetchOrderDetails(orderId));
  },
  fetchProducts(storeUrlEndpoint: string, request: GetProductsRequest) {
    dispath(triggerFetchProducts(storeUrlEndpoint, request));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderStatusView);